<template>
  <v-card v-if="id">
    <v-card-title class="primary lighten-1 white--text">
      [{{ chat.vendor }}] {{ chat_name }} ({{ chat.data.user.name }})
      <v-spacer />
      <v-btn dark icon @click="$emit('close')" title="Закрыть"><v-icon>fas fa-times</v-icon></v-btn>
    </v-card-title>
    <a-loader v-if="loading" />
    <v-card-text class="whatsapp-chat" ref="chat_block">
      <div class="whatsapp-chat-row" v-for="(el, i) of data" :key="i">
        <div v-if="i == data.length - 1 || getDateString(el.createdon) !== getDateString(data[i + 1].createdon)" class="row-date">
          <div class="row-date-inner">{{ getDateString(el.createdon) }}</div>
        </div>
        <div v-if="testShowNewMess(el)" class="row-date">
          <div class="row-date-inner">Новое сообщение</div>
        </div>
        <message :item="el" />
      </div>
    </v-card-text>
    <v-card-actions class="whatsapp-input-wrapper pa-1 blue-grey darken-3 white--text" style="position: absolute; height: 58px; bottom: 0; width: 100%">
      <div class="d-flex" style="width: 100%">
        <div v-if="false" class="pt-3 ml-1 mx-2 wa-upload">
          <label for="wa-upload-file"><v-icon dark>fas fa-paperclip </v-icon> </label>
          <input type="file" name="wa-file" id="wa-upload-file" @change="onChangeFile" />
        </div>
        <div style="width: 100%">
          <input class="whatsapp-input" v-model="textMessage" placeholder="Введите сообщение" />
        </div>
        <div class="pa-1 ml-1">
          <v-btn dark icon @click="sendMessage(textMessage)" title="Послать сообщение"><v-icon>fas fa-paper-plane </v-icon></v-btn>
        </div>
      </div>
    </v-card-actions>

    <v-dialog v-model="uploadLoading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Пожалуста подождите
          <v-progress-linear :value="uploadStatus" color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  components: {
    message: () => import("./message.vue"),
    templateDialog: () => import("./templateDialog.vue"),
  },
  props: {
    id: Number,
    isAll: {
      type: Boolean,
      default: false,
    },
    chat: { type: Object, default: () => {} },
  },
  data() {
    return {
      loading: false,
      data: [],
      textMessage: "",
      pager: null,
      isTheEnd: null,
      showTemplateDialog: false,
      messageBlockDialog: false,
      canSendingMessages: false,
      uploadLoading: false,
      uploadStatus: 0,
    };
  },
  created() {
    this.$root.$on("websocket", this.registerWS);
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll, true);
  },
  destoyed() {
    document.removeEventListener("scroll", this.onScroll, true);
    this.$root.$off("websocket", this.registerWS);
  },

  computed: {
    chat_name() {
      let res = this.chat?.data?.offer?.title || this.chat.id;
      return res;
    },
    number() {
      return this.id;
    },
    messageUser() {
      let res = !this.item.createdby_id ? this.item.lead_name : this.item.user_name;
      return res;
    },
    messageDate() {
      const d = new Date(this.item.createdon);
      const n = new Date();
      let res = d.date == n.date ? d.shorttime : d.datetime;
      return res;
    },
  },
  watch: {
    number: {
      handler() {
        this.uploadLoading = false;
        this.uploadStatus = 0;
        this.textMessage = "";
        this.data = [];
        this.showNewMess = false;
        this.pager = {
          page: 0,
          limit: 30,
        };
        this.isTheEnd = false;
        this.fetchData();
      },
      immediate: true,
    },
  },
  methods: {
    testShowNewMess(mess) {
      if (mess.viewed == 0 && !this.showNewMess) {
        this.showNewMess = true;
        return true;
      }
      return false;
    },
    onScroll() {
      if (this.$refs.chat_block) {
        let el = this.$refs.chat_block;

        let offset = 200;
        if (el.scrollHeight - el.offsetHeight <= -el.scrollTop + offset) {
          this.onScrollEnd();
        }
      }
    },
    onScrollEnd() {
      if (!this.isTheEnd && !this.loading) {
        this.pager.page++;
        this.fetchData();
      }
    },
    registerWS(data) {
      let regEvent = "whatsapp";
      // if (this.isAll) regEvent = "whatsapp_all"
      if (data && data.e == regEvent) {
        const r = data.r;
        if (r.direction == 1 && r.sender_number == this.number) {
          this.data.unshift(r);
        } else if (r.direction == 2 && r.recipient_number == this.number) {
          this.data.unshift(r);
        }
      } else if (data && data.e == "whatsapp-status") {
        for (const el of this.data) {
          if (el.id == data.r.id) {
            el.status = data.r.status;
          }
        }
      }
      this.setViewed();
    },
    async sendMessage(message) {
      if (message) {
        try {
          await this.$axios.post("/mechti/chats_message", {
            type: "text",
            message: message,
            chat_id: this.number,
          });
          this.textMessage = "";
        } catch (error) {
          console.error(error);
        }
      }
    },

    async setViewed() {
      // if (this.isAll) return;
      try {
        let count = 0;
        return;
        for (const el of this.data) {
          if (el.viewed != 1) {
            await this.$axios.post("/mechti/chats_message", {
              id: el.id,
              viewed: 1,
            });
            count++;
          }
        }
        //this.$root.$emit("set_viewed_wa", { sender_number: this.number });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchData() {
      if (!this.number) return;
      try {
        this.loading = true;
        const params = {
          sort: { key: "createdon", order: "desc" },
          filters: { chat_id: this.id },
          pager: JSON.stringify(this.pager),
        };
        const response = await this.$axios.get("/mechti/chats_message/", { params });
        if (this.pager.page > 0) {
          for (const el of response.data.data) {
            this.data.push(el);
          }
        } else {
          this.data = response.data.data;
          // if (!this.canSendingMessages) this.messageBlockDialog = true;
        }
        this.setViewed();
        if (response.data.data.length < this.pager.limit) this.isTheEnd = true;
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
    getDateString(d) {
      let a = new Date(d);
      return a.toLocaleDateString("ru");
    },
    getTimeString(d) {
      let a = new Date(d);
      return a.toLocaleTimeString("ru", { hour: "2-digit", minute: "2-digit" });
    },
  },
};
</script>

<style lang="scss">
.wa-upload {
  label {
    cursor: pointer;
  }

  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}

.whatsapp-input-wrapper {
  // background: #aaa;
}

.whatsapp-input {
  background: #fff;
  border-radius: 6px;
  margin: 5px;
  padding: 8px 10px;
  width: 100%;
  outline: none;
  caret-color: auto;
}

.whatsapp-chat {
  position: absolute;
  overflow: auto;
  top: 62px;
  bottom: 58px;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  background: url(/img/bg-chat-tile-dark_a4be512e7195b6b733d9110b408f075d.png) repeat #eee;
}

.whatsapp-chat-row {
  .row-date {
    display: flex;
    justify-content: center;

    .row-date-inner {
      margin: 10px;
      padding: 5px 10px;
      background-color: #fff;
      box-shadow: 0px 1px 0 #e2e2e2;
      border-radius: 7.5px;
    }
  }

  .row-message {
    padding: 0;
    margin: 2px 10px 2px 10px;
    display: flex;

    &.left {
      justify-content: left;

      .row-message-inner {
        background-color: #fff;
      }
    }

    &.right {
      justify-content: right;

      .row-message-inner {
        background-color: #d9fdd3;
      }
    }

    .row-message-inner {
      box-shadow: 0px 1px 0 #e2e2e2;
      white-space: pre-line;
      max-width: 400px;
      border: 1px solid transparent;
      border-radius: 7.5px;
      padding: 8px 10px 2px 10px;
    }

    .row-message-inner-date {
      font-size: 12px;
      color: #aaa;
      margin-left: 30px;
      text-align: right;
    }
  }
}
</style>
